import { proDomains } from './intronet'

const AD_DOMAIN = 'https://aolsee.yuantutech.com/aolsee-web'
const AD_DOMAIN_UAT = 'https://advertisement-aliuat.yuantutech.com/aolsee-web'

const AD_COUNTER = 'https://s.yuantutech.com/tms/piros/ad-counter.html'
const AD_COUNTER_UAT = 'https://daily.yuantutech.com/tms/piros/ad-counter.html'

const TRANSFER_URL = 'https://s.yuantutech.com/tms/h5/transfer.php?'
const TRANSFER_URL_UAT = 'https://uat.yuantutech.com/tms/h5/transfer.php?'

const UPLOAD_URL = AD_DOMAIN_UAT + '/upload2'
const UPLOAD_URL_INTRANET = AD_DOMAIN_UAT + '/intranet/upload'

let UAT_FLAG = false
let INTRANET_FLAG = false
let host = window.location.host

if (
  host.indexOf("daily.") !== -1 ||
  host.indexOf("uat.") !== -1 ||
  host.indexOf("localhost") !== -1 ||
  host.indexOf("127.0.0.1") !== -1 ||
  host.indexOf("0.0.0.0") !== -1
) {
  UAT_FLAG = true
}

if ((window as any).config.isIntronet) {
  INTRANET_FLAG = true
}

var config = {
  AD_DOMAIN: INTRANET_FLAG
    ? (proDomains as any)[(window as any).config.corp].domain
    : UAT_FLAG
      ? AD_DOMAIN_UAT
      : AD_DOMAIN,
  AD_COUNTER: UAT_FLAG ? AD_COUNTER_UAT : AD_COUNTER,
  TRANSFER_URL: UAT_FLAG ? TRANSFER_URL_UAT : TRANSFER_URL,
  UPLOAD_URL: INTRANET_FLAG ? UPLOAD_URL_INTRANET : UPLOAD_URL
}

export default config