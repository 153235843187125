import { message } from 'antd'
import axios from 'axios'
import config from './config'

const { AD_DOMAIN } = config
interface IConfig {
  SPECIAL_DOMAIN?: string
  timeout?: number
  headers?: object
}

type Method =
  | 'get' | 'GET'
  | 'delete' | 'DELETE'
  | 'head' | 'HEAD'
  | 'options' | 'OPTIONS'
  | 'post' | 'POST'
  | 'put' | 'PUT'
  | 'patch' | 'PATCH'
  | 'purge' | 'PURGE'
  | 'link' | 'LINK'
  | 'unlink' | 'UNLINK'

const request = (method: Method='GET', url: string, params: any, config: IConfig ={}) => {
  let {
    SPECIAL_DOMAIN,
    timeout=10000,
    headers
  } = config

  for (let key in params) {
    if(params[key] === null) {
      delete params[key]
    }
  }

  const instance = axios.create({
    baseURL: SPECIAL_DOMAIN || AD_DOMAIN,
    timeout,
    withCredentials: true,
    headers
  })
  instance.interceptors.response.use(response => {
    if (response.data.resultCode === '202') {
      message.warning('未登录，请重新登录')
      window.location.href = 'https://uat.yuantutech.com/tms/piros/login.php'
    }
    return response
  })
  if (method === 'GET') {
    return instance({
      method,
      url,
      params: params,
      headers
    })
  } else{
    let formData = new FormData()
    Object.entries(params).forEach((item: Array<any>) => {
      formData.append(item[0], item[1])
    })
    return instance({
      method,
      url,
      data: formData
    })
  }
}

export default request