import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { 
  Layout,
  Avatar,
  Row,
  Col,
  Dropdown,
  Space,
  Menu,
  TreeSelect,
  message
} from 'antd'
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined
} from '@ant-design/icons'

import api from 'api/api'
import store from 'store'

import styles from './index.less'

const { Header } = Layout

const Index = (props: any) => {
  const {
    user: {
      user: {
        account = {}
      },
      currentOrg = {}
    },
    union: {
      unionList = []
    }
  } = store
  let { className, style, collapsed, setCollapsed } = props
  const [unionCorpData, setUnionCorpData] = useState<any[]>([])            // 全部机构列表
  const [unionCorpValue, setUnionCorpValue] = useState(currentOrg.corpId || currentOrg.unionId) // 新关联列表的key
  
  useEffect(() => {
    getUnionCorpData()
  }, [unionList])

  const getUnionCorpData = () => {
    let tmpData: any = []
    unionList.forEach((item: any) => {
      let tmpItem: any = {}
      tmpItem.title = item.unionName
      tmpItem.value = item.unionId
      tmpItem.key = item.unionId
      tmpItem.children = []
      if (item.corpVOList) {
        item.corpVOList.forEach((subItem: any) => {
          let tmpSubItem: any = {}
          tmpSubItem.title = subItem.corpName
          tmpSubItem.value = subItem.corpId
          tmpSubItem.key = subItem.corpId
          tmpItem.children.push(tmpSubItem)
        })
      }
      tmpData.push(tmpItem)
    })
    setUnionCorpData(tmpData)
  }

  const changTree = (value: any) => {
    setUnionCorpValue(value)
    selectUnionCorp(value)
  }

  const selectUnionCorp = (value: any) => {
    let params: any = {}
    unionList.forEach((item: any) => {
      if (item.unionId === value) {
        params.unionId = item.unionId
        params.unionName = item.unionName
      }
      if (item.corpVOList) {
        item.corpVOList.forEach((subItem: any) => {
          if (value === subItem.corpId) {
            params = subItem
            params.unionId = item.unionId
            params.unionName = item.unionName
          }
        })
      }
    })

    api.selectUnionCorp(params)
      .then((res) => {
        if (res.data.success) {
          store.user.getUnionCorp()
          message.success('切换机构成功')
        } else {
          message.error(res.data.msg)
        }
      })
  }

  return (
    <Header  className={className} style={style} >
      <Row>
        <Col span={4}>
          {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: styles.trigger,
            onClick: _ => setCollapsed(!collapsed)
          })}
        </Col>
        <Col
          span={20}
          style={{ 
            padding: '0 24px',
            textAlign: 'right'
          }}
        >
          <TreeSelect
            treeData={unionCorpData}
            value={unionCorpValue}
            onChange={changTree}
            showSearch={true}
            placeholder={'请选择投放机构'}
            treeNodeFilterProp='title'
            style={{
              width: 220,
              marginRight: 20,
              textAlign: 'left'
            }}
          />
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item>
                  退出登录
                </Menu.Item>
              </Menu>
            }
          >
            <Space>
              <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />          
              <span className="white-color">
                {account.name}
              </span>                   
            </Space>
          </Dropdown>  
        </Col>
      </Row>
    </Header>
  )
}

export default observer(Index)