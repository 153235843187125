import { useState, Suspense, useRef } from 'react'
import { Switch, Route, Link, useHistory } from 'react-router-dom'
import { observer } from 'mobx-react'
import { Layout, Menu, Spin, Skeleton } from 'antd'
import menu from 'routes/menu'
import routes from 'routes'
import Header from 'container/Header'
import NotFound from '../NotFound'
import store from 'store'

import styles from './index.less'

const { Sider, Content } = Layout
const { SubMenu } = Menu

const Index = () => {
  const history = useHistory()
  const initSelectKey = history.location.pathname.replace(/^(\/[^/]*\/[^/]*)\/.*/, '$1')
  const {
    user: {
      user: {
        role={}
      },
      currentOrg:{
        unionId
      }
    }
  } = store
  const [collapsed, setCollapsed] = useState(false)
  const [selectedKeys, setSelectedKeys] = useState<string[]>([initSelectKey])
  const [defaultOpenKeys, setDefaultOpenKeys] = useState<any[]>([])

  const useComponentWillMount = (func: any) => {
    const willMount = useRef(true)
    if (willMount.current) func()
    willMount.current = false
  }

  useComponentWillMount(() => {
    menu.forEach((menuItem) => {
      if (menuItem.children) {
        menuItem.children.some((subMenu) => {
          if (subMenu.path === selectedKeys[0]) {
            setDefaultOpenKeys([menuItem.path])
          }
          return subMenu.path === selectedKeys[0]
        })
      }
    })
  })

  let flatten = (arr:any) => {
    return arr.reduce((pre:any, next:any) =>{
      return pre.concat(next.children ? flatten(next.children) : {
        title: next.title,
        path: next.path,
        icon: next.icon,
        component: next.component
      })
    }, [])
  }

  return (
    <div className={styles.mainApp}>
      {
        unionId
          ? <Layout
              style={{
                width: '100%',
                height: '100%'
              }}
            >
              <Sider trigger={null} collapsible collapsed={collapsed} >
                <div className={styles.logo}>
                  <img src={require('assets/images/logo-white.png').default} alt="投放logo" />
                </div>
                <Menu
                  theme="dark"
                  mode="inline"
                  selectedKeys={selectedKeys}
                  defaultOpenKeys={defaultOpenKeys}
                >
                  {
                    menu.map((menuItem) => {
                      if (menuItem.children) {
                        if (role.value !== 'advertisementAdmin' && menuItem.inside) {
                          return
                        }
                        return (
                          <SubMenu key={menuItem.path} title={menuItem.title} icon={menuItem.icon}>
                            {menuItem.children.map((subMenu) => {
                              if (role.value !== 'advertisementAdmin' && subMenu.inside) {
                                return
                              }
                              return (
                                <Menu.Item
                                  key={subMenu.path}
                                  icon={subMenu.icon}
                                  onClick={() => {
                                    setSelectedKeys([subMenu.path])
                                  }}
                                >
                                  <Link to={subMenu.path}>{subMenu.title}</Link>
                                </Menu.Item>
                              )
                            })}
                          </SubMenu>
                        )
                      } else {
                        if (role.value !== 'advertisementAdmin' && menuItem.inside) {
                          return
                        }
                        return (
                          <Menu.Item
                            key={menuItem.path}
                            icon={menuItem.icon}
                            onClick={() => {
                              setSelectedKeys([menuItem.path])
                            }}
                          >
                            <Link to={menuItem.path}>{menuItem.title}</Link>
                          </Menu.Item>
                        )
                      }
                    })
                  }
                </Menu>
              </Sider>
              <Layout>
                <Header
                  className={styles.layoutBackground}
                  style={{ padding: 0 }}
                  collapsed={collapsed}
                  setCollapsed={setCollapsed}
                />
                <Content 
                  className={styles.layoutBackground}
                  style={{
                    overflow: 'scroll',
                    margin: 12,
                    padding: '12px 24px'
                  }}
                >
                  <Suspense fallback={<Spin />}>
                    <Switch>
                      {flatten(routes).map((route: any, index: any) => {
                        return <Route key={index} exact path={route.path} component={route.component} />
                      })}
                      <Route path='*' component={NotFound} />
                    </Switch>
                  </Suspense>
                </Content>
              </Layout>
            </Layout>
          : <div
              style={{
                width: '100%',
                height: '100%'
              }}
            >
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
            </div>
      }
    </div>
  )
}

export default observer(Index)