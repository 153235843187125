import Icon, {
  DatabaseOutlined,
  SettingOutlined,
  CloudDownloadOutlined,
  ClusterOutlined,
  ScheduleOutlined,
  PlusSquareOutlined
} from '@ant-design/icons'

const yuantuIcon = require('assets/images/yuantuIcon.svg').default
interface IRoute {
  title: string
  path: string
  icon?: any
  inside?: boolean
  component?: any
  children?: Array<IRoute>
}

const routes: Array<IRoute> = [
  {
    title: '投放管理',
    path: '/ad',
    icon: <DatabaseOutlined />,
    inside: false,
    children: [
      {
        title: '远图线上投放',
        path: '/ad/online',
        icon: <Icon component={yuantuIcon} />,
        inside: true
      },
      {
        title: '线上投放排序',
        path: '/ad/sort',
        icon: <Icon component={yuantuIcon} />,
        inside: true
      },
      {
        title: '医院线下投放',
        path: '/ad/offline',
        icon: <PlusSquareOutlined />,
        inside: false
      }
    ]
  },
  {
    title: '投放设备管理',
    path: '/device',
    icon: <ClusterOutlined />,
    inside: false,
    children: [
      {
        title: '设备管理',
        path: '/device/manage',
        icon: <PlusSquareOutlined />,
        inside: false
      },
      {
        title: '设备分组管理',
        path: '/device/group',
        icon: <PlusSquareOutlined />,
        inside: false
      }
    ]
  },
  {
    title: '投放计划管理',
    path: '/plan',
    icon: <ScheduleOutlined />,
    inside: true,
    children: [
      {
        title: '投放计划',
        path: '/plan/manage',
        icon: <Icon component={yuantuIcon} />,
        inside: true
      },
      // {
      //   title: '投放效果分析',
      //   path: '/plan/analyze',
      //   icon: <UserOutlined />
      // }
    ]
  },
  {
    title: '素材库',
    path: '/resource',
    icon: <CloudDownloadOutlined />,
    inside: false,
    children: [
      {
        title: '我的素材',
        path: '/resource/mine',
        icon: <PlusSquareOutlined />,
        inside: false
      },
      {
        title: '公共素材库',
        path: '/resource/public',
        icon: <PlusSquareOutlined />,
        inside: false
      },
      {
        title: '素材内容管理',
        path: '/resource/manage',
        icon: <Icon component={yuantuIcon} />,
        inside: true
      }
    ]
  },
  {
    title: '系统设置',
    path: '/system',
    icon: <SettingOutlined />,
    inside: true,
    children: [
      {
        title: '投放位管理',
        path: '/system/position',
        icon: <Icon component={yuantuIcon} />,
        inside: true
      },
      {
        title: '操作日志',
        path: '/system/log',
        icon: <Icon component={yuantuIcon} />,
        inside: true
      }
    ]
  }
]

export default routes