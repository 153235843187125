import { lazy } from 'react'

const routes = [
  /*--------------------------- 线上投放 -----------------------------*/
  {
    path: '/ad/online',
    component: lazy(() => import('container/AdManage/Online'))
  },
  {
    path: '/ad/online/add',
    component: lazy(() => import('container/AdManage/Online/AddOnline'))
  },
  /*--------------------------- 线上投放排序 -----------------------------*/
  {
    path: '/ad/sort',
    component: lazy(() => import('container/AdManage/Sort'))
  },
  {
    path: '/ad/sort/detail/:id',
    component: lazy(() => import('container/AdManage/Sort/SortDetail'))
  },
  /*--------------------------- 线下投放 -----------------------------*/
  {
    path: '/ad/offline',
    component: lazy(() => import('container/AdManage/Offline'))
  },
  {
    path: '/ad/offline/add',
    component: lazy(() => import('container/AdManage/Offline/AddOffline'))
  },
  /*--------------------------- 设备和分组管理 -----------------------------*/
  {
    path: '/device/manage',
    component: lazy(() => import('container/DeviceManage/Manage'))
  },
  {
    path: '/device/group',
    component: lazy(() => import('container/DeviceManage/Group'))
  },
  /*--------------------------- 投放计划 -----------------------------*/
  {
    path: '/plan/manage',
    component: lazy(() => import('container/PlanManage/Manage'))
  },
  {
    path: '/plan/analyze',
    component: lazy(() => import('container/PlanManage/Analyze'))
  },
  /*--------------------------- 素材库 -----------------------------*/
  {
    path: '/resource/mine',
    component: lazy(() => import('container/ResourceLib/Mine'))
  },
  {
    path: '/resource/public',
    component: lazy(() => import('container/ResourceLib/Public'))
  },
  {
    path: '/resource/manage',
    component: lazy(() => import('container/ResourceLib/Manage'))
  },
  /*--------------------------- 系统设置 -----------------------------*/
  {
    path: '/system/position',
    component: lazy(() => import('container/SysManage/Position'))
  },
  {
    path: '/system/log',
    component: lazy(() => import('container/SysManage/Log'))
  }
]

export default routes