import api from 'api/api'
import { makeAutoObservable } from 'mobx'

class Channel {
  constructor() {
    makeAutoObservable(this)
    this.getListNewChannel()
    this.getOnlineChannel()
    this.getAdPosition()
    this.getPositionType()
    this.getStatusList()
  }
  channelList = []        // 所有渠道
  onlineChannelList = []  // 线上渠道
  positionList = []       // 所有投放位
  positionType = []       // 投放位类型
  statusList = []         // 投放状态分类

  getListNewChannel() {
    api.getListNewChannel()
      .then((res) => {
        this.channelList = res.data.data
      })
  }
  
  getOnlineChannel() {
    api.getOnlineChannel()
      .then((res) => {
        this.onlineChannelList = res.data.data
      })
  }

  getAdPosition() {
    api.getAdPosition()
      .then((res) => {
        this.positionList = res.data.data
      })
  }

  getPositionType() {
    api.getPositionType()
      .then((res) => {
        this.positionType = res.data.data
      })
  }

  getStatusList() {
    api.getStatusList()
      .then((res) => {
        this.statusList = res.data.data
      })
  }
}

export default new Channel()