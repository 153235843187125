import api from 'api/api'
import { makeAutoObservable } from 'mobx'

class Union {
  constructor() {
    makeAutoObservable(this)
    this.getUnionList()
  }
  unionList = [] // 机构列表

  async getUnionList() {
    let res = await api.getUnionList()
    this.unionList = res.data?.data
  }
}

export default new Union()