import { useState, useEffect } from 'react'
import classnames from 'classnames'
import { Form, Input, Button } from 'antd'
import { UserOutlined, LockOutlined, MailOutlined, MobileOutlined } from '@ant-design/icons'

import api from 'api/api'
import styles from './login.less'

const md5 = require('blueimp-md5')

const Index = function () {
  let [loginType, setLoginType] = useState(0) // 0 账号密码登录 1 手机号验证码登录 2 找回密码
  let [countdownFlag, setCountdownFlag] = useState(false)
  let [countdown, setCountdown] = useState(10)
  const [accountForm] =Form.useForm()
  const [codeForm] =Form.useForm()
  const [retrieveForm] =Form.useForm()

  const desc = loginType !== 2
    ? '一站式管理平台，助力医院智慧服务评级，开启患者就诊全流程优化服务新体验'
    : '找回密码'
  let loginHeaderClass = classnames({
    [styles.loginHeader]: true,
    [styles.loginHeaderRegist]: loginType === 2
  })
  let accountClass = classnames({
    [styles.loginTab]: true,
    [styles.active]: loginType === 0
  })
  let codeClass = classnames({
    [styles.loginTab]: true,
    [styles.active]: loginType === 1
  })

  useEffect(() => {
    let codeTimer = setInterval(() => {
      countdown--
      if(countdown < 0) {
        countdown = 10
        clearInterval(codeTimer)
        setCountdownFlag(false)
      }
      setCountdown(countdown)
    }, 1000)
    return () => clearInterval(codeTimer)
  }, [countdownFlag])

  const loginAccount = () => {
    accountForm.validateFields()
      .then(values => {
        // values.password = md5(values.password)
        // api.login(values).then(() => {

        // })
        api.getUserInfo()
          .then(res => {
          })
          .catch()
      })
      .catch()
  }

  return (
    <div className={styles.login}>
      <div className={styles.loginWrap}>
        <dl className={loginHeaderClass}>
          <dt className={styles.sysLogo}>
            <img src={require('../../assets/images/logo-black.png').default} alt='投放logo' />
          </dt>
          <dd className={styles.sysDesc}>{desc}</dd>
        </dl>
        <div className={styles.loginContent}>
          {
            (loginType === 0 || loginType === 1)
              &&  <ul className={styles.loginTabWrap}>
                    <li
                      className={accountClass}
                      onClick={_ => setLoginType(0)}
                    >
                      账户密码登录
                    </li>
                    <li
                      className={codeClass}
                      onClick={_ => setLoginType(1)}
                    >
                      验证码登录
                    </li>
                  </ul>
          }
          {
            loginType === 0
              &&  <Form form={accountForm}>
                    <Form.Item
                      name='loginName'
                      rules={[
                        {
                          required: true,
                          message: '请输入您的账号',
                        },
                      ]}
                    >
                      <Input size="large" placeholder="请输入您的账号" prefix={<UserOutlined />} />
                    </Form.Item>
                    <Form.Item
                      name='password'
                      rules={[
                        {
                          required: true,
                          message: '请输入您的密码',
                        },
                      ]}
                    >
                      <Input.Password size="large" placeholder="请输入您的密码" prefix={<LockOutlined />} />
                    </Form.Item>
                    <p
                      className={styles.forgetPassword}
                      onClick={_ => setLoginType(2)}
                    >
                      忘记密码?
                    </p>
                    <Form.Item>
                      <Button
                        type="primary"
                        block
                        onClick={_ => {loginAccount()}}
                      >
                        登录
                      </Button>
                    </Form.Item>
                  </Form>
          }
          {
            loginType === 1
              &&  <Form>
                    <Form.Item>
                      <Input size="large" placeholder="请输入您的手机号" prefix={<MobileOutlined />} />
                    </Form.Item>
                    <Form.Item>
                      <Input size="large" style={{width: 238}} placeholder="请输入6位验证码" prefix={<MailOutlined />} />
                      {
                        countdownFlag
                          ? <Button
                              size="large"
                              style={{
                                float: 'right'
                              }}
                              disabled
                            >
                              {countdown}s后可重发
                            </Button>
                          : <Button
                              size="large"
                              style={{
                                float: 'right'
                              }}
                              onClick={_ => setCountdownFlag(true)}
                            >
                              获取验证码
                            </Button>
                      }
                    </Form.Item>
                    <p className={styles.codeTips}>接收验证码可能存在延迟，请耐心等待</p>
                    <Form.Item>
                      <Button type="primary" block>登录</Button>
                    </Form.Item>
                  </Form>
          }
          {
            loginType === 2
              &&  <Form>
                    <Form.Item>
                      <Input size="large" placeholder="请输入您的手机号" prefix={<MobileOutlined />} />
                    </Form.Item>
                    <Form.Item>
                      <Input size="large" placeholder="请输入6位验证码" prefix={<MailOutlined />} />
                    </Form.Item>
                    <Form.Item>
                      <Input.Password size="large" placeholder="请输入6-16位新密码" prefix={<LockOutlined />} />
                    </Form.Item>
                    <Form.Item>
                      <Button type="primary" block>登录</Button>
                    </Form.Item>
                    <Button
                      type="link"
                      block
                      onClick={_ => setLoginType(0)}
                    >
                      去登录
                    </Button>
                  </Form>
          }
        </div>
      </div>
    </div>
  )
}

export default Index