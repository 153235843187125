/**
 * 内网地址
*/

const proDomains = {
  jssz: {
    desc: "江苏省中",
    domain: "http://172.20.17.166:8083/aolsee-web"
  },
  hzsy: {
    desc: "杭州市一",
    domain: "http://192.200.53.145:8091/aolsee-web"
  },
  aliyun: {
    desc: "阿里云内网测试",
    domain: "https://aolseein-aliuat.yuantutech.com/aolsee-web"
  },
  hzdq: {
    desc: "杭州市中医院丁桥院区",
    domain: "http://10.51.102.39:8190/aolsee-web"
  },
  hzxx: {
    desc: "杭州市西溪医院",
    domain: "http://192.168.118.225:8091/aolsee-web"
  },
}
const uatDomains = {
  jssz: {
    desc: "江苏省中",
    domain: "http://172.20.17.164:8083/aolsee-web"
  },
  hzsy: {
    desc: "杭州市一",
    domain: "http://192.200.53.144:8091/aolsee-web"
  },
  aliyun: {
    desc: "阿里云内网测试",
    domain: "https://aolseein-aliuat.yuantutech.com/aolsee-web"
  }
}

export {
  proDomains,
  uatDomains
}