import { Result, Button } from 'antd'
import { Link } from 'react-router-dom'


const Index = () => {
  return (
    <Result
      status="404"
      title="404"
      subTitle="页面不小心飞走啦～"
      extra={
        <Button
          type="primary"
        >
          <Link to="/ad/online">回到首页</Link>
        </Button>
      }
    />
  )
}

export default Index