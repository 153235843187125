import user from './user'
import channel from './channel'
import union from './union'
import resource from './resource'

const store = {
  user,
  channel,
  union,
  resource
}

export default store