import request from './request'

const api = {
  login(params?: object) {
    return request('POST', '/api/intranet/doLogin', params)
  },
  // 获取用户信息
  getUserInfo(params?: object) {
    return request('GET', '/admin/getUserInfo', params)
  },
  // 获取机构列表
  getUnionList(params?: object) {
    return request('GET', '/commonApi/listUnionForAd.json', params)
  },
  // 获取当前机构
  getUnionCorp(params?: object) {
    return request('GET', '/commonApi/getUnionCorp.json', params)
  },
  // 切换当前机构
  selectUnionCorp(params?: object) {
    return request('GET', '/commonApi/selectUnionCorp.do', params)
  },
  // 获取渠道列表
  getListNewChannel(params?: object) {
    return request('GET', '/commonApi/listNewChannel.json', params)
  },
  // 线上渠道分类
  getOnlineChannel(params?: object) {
    return request('GET', '/commonApi/onlineChannel.json', params)
  },
  // 投放状态分类
  getStatusList(params?: object) {
    return request('GET', '/commonApi/listAdStatus.json', params)
  },
  /*--------------------------- 线上投放列表 -----------------------------*/
  // 获取线上投放列表
  getOnlinePage(params?: object) {
    return request('GET', '/adApi/onlinePage.json', params)
  },
  // 新增线上投放
  addOnline(params?: object) {
    return request('POST', '/adApi/onlineAdd.do', params)
  },
  // 投放上下线
  updateOnlineStatus(params?: object) {
    return request('GET', '/adApi/updateStatus.do', params)
  },
  // 删除线上投放
  deleteOnline(params?: object) {
    return request('GET', '/adApi/delete.do', params)
  },
  /*--------------------------- 线上投放排序-----------------------------*/
  // 根据渠道获取投放位
  getOnlineAdRank(params?: object) {
    return request('GET', '/orderApi/onlineAdRank', params)
  },
  // 获取具体投放位的广告排序
  getRankDetails(params?: object) {
    return request('GET', '/orderApi/rankDetails', params)
  },
  // 排序向上
  rankingUp(params?: object) {
    return request('GET', '/orderApi/rankingUp', params)
  },
  // 排序向下
  rankingDown(params?: object) {
    return request('GET', '/orderApi/rankingDown', params)
  },
  // 修改机构和人群
  updateRankOrg(params?: object) {
    return request('POST', '/orderApi/operate', params)
  },
  /*--------------------------- 线下设备投放 -----------------------------*/
  // 获取设备投放列表
  getDevicePage(params?: object) {
    return request('GET', '/deviceAdApi/deviceAdPage.json', params)
  },
  // 获取所有设备投放位
  getDevicePosition(params?: object) {
    return request('GET', '/deviceAdApi/getDeviceAdPositions', params)
  },
  // 获取投放详情
  getAdDetail(params?: object) {
    return request('GET', '/adApi/detail.json', params)
  },
  // 更新投放
  updateAd(params?: object) {
    return request('POST', '/adApi/update.do', params)
  },
  // 新增投放
  addOfflineAd(params?: object) {
    return request('POST', '/deviceAdApi/addDeviceAd', params)
  },
  /*--------------------------- 设备管理 -----------------------------*/
  // 获取全部设备列表
  getListDevice(params?: object) {
    return request('GET', '/adApi/listDevice3.json', params)
  },
  // 获取已导入设备列表
  getDeviceGroupPage(params?: object) {
    return request('GET', '/deviceAdApi/deviceGroupPage.json', params)
  },
  // 导入设备
  importCorpDevice(params?: object) {
    return request('POST', '/deviceAdApi/importCorpDevice', params)
  },
  // 获取设备详情
  getDeviceDetail(params?: object) {
    return request('GET', '/deviceAdApi/deviceDetail', params)
  },
  // 更新设备信息
  updateDeviceInfo(params?: object) {
    return request('POST', '/deviceAdApi/updateDeviceInfo', params)
  },
  // 设备添加投放位
  addDevicePosition(params?: object) {
    return request('POST', '/deviceAdApi/addDevicePosition', params)
  },
  /*--------------------------- 设备分组 -----------------------------*/
  // 获取设备分组列表
  getDeviceGroup(params?: object) {
    return request('GET', '/deviceAdApi/deviceGroupQuery', params)
  },
  // 获取分组下的设备
  getGroupDevice(params?: object) {
    return request('GET', '/deviceAdApi/getGroupDevice', params)
  },
  // 添加分组
  addGroup(params?: object) {
    return request('POST', '/deviceAdApi/addGroup', params)
  },
  // 更新设备分组
  updateDeviceGroup(params?: object) {
    return request('GET', '/deviceAdApi/updateDeviceGroup', params)
  },
  // 删除分组
  deleteDeviceGroup(params?: object) {
    return request('GET', '/deviceAdApi/deleteGroup', params)
  },
  /*--------------------------- 投放计划 -----------------------------*/
  // 获取投放计划列表
  getAdPlan(params?: object) {
    return request('GET', '/adPlanApi/getAdPutPlan', params)
  },
  // 获取投放计划列表(无分页)
  getAdPlanList(params?: object) {
    return request('GET', '/adPlanApi/getAdPlanList', params)
  },
  // 获取计划关联广告列表
  getRelevantAd(params?: object) {
    return request('GET', '/adPlanApi/getRelevantAd', params)
  },
  // 新增投放计划
  addAdPlan(params?: object) {
    return request('GET', '/adPlanApi/addAdPlan', params)
  },
  // 修改投放计划
  editAdPlan(params?: object) {
    return request('POST', '/adPlanApi/editAdPlan', params)
  },
  // 删除投放计划
  deleteAdPlan(params?: object) {
    return request('GET', '/adPlanApi/deleteAdPlan', params)
  },
  /*--------------------------- 我的素材 -----------------------------*/
  // 获取我的素材列表
  getMyResource(params?: object) {
    return request('GET', '/adMaterialApi/page.json', params)
  },
  // 获取我的素材详情
  getMyResourceDetail(params?: object) {
    return request('POST', '/adMaterialApi/detail.json', params)
  },
  // 新增我的素材
  addMyResource(params?: object) {
    return request('POST', '/adMaterialApi/add.do', params)
  },
  // 更新我的素材
  updateMyResource(params?: object) {
    return request('POST', '/adMaterialApi/update.do', params)
  },
  // 删除我的素材
  deleteMyResource(params?: object) {
    return request('GET', '/adMaterialApi/delete.do', params)
  },
  /*--------------------------- 公共素材 -----------------------------*/
  // 获取公共素材列表
  getPublicResource(params?: object) {
    return request('GET', '/adMaterialApi/publicMaterialQuery', params)
  },
  // 获取公共素材栏目分组
  getResourceColumn(params?: object) {
    return request('GET', '/adMaterialApi/getMaterialColumn', params)
  },
  // 新增公共素材
  addPublicResource(params?: object) {
    return request('POST', '/adMaterialApi/addPublicResource.do', params)
  },
  // 获取素材详情
  getResourceDetail(params?: object) {
    return request('GET', '/adMaterialApi/publicMaterialDetails', params)
  },
  // 更新素材
  updatePubliceResource(params?: object) {
    return request('POST', '/adMaterialApi/updateMaterial', params)
  },
  // 下载公共素材
  downloadResource(params?: object) {
    return request('GET', '/adMaterialApi/downloadMaterial', params, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}})
  },
  // 删除公共素材
  deletePublicResource(params?: object) {
    return request('GET', '/adMaterialApi/publicMaterialDelete', params)
  },
  /*--------------------------- 投放位管理 -----------------------------*/
  // 获取投放位列表
  getAdPosition(params?: object) {
    return request('GET', '/adPositionApi/list.json', params)
  },
  // 获取投放位类型
  getPositionType(params?: object) {
    return request('GET', '/commonApi/listAdPositionType.json', params)
  },
  // 新增投放位
  addAdPosition(params?: object) {
    return request('GET', '/adPositionApi/add.do', params)
  },
  // 更新投放位
  updateAdPosition(params?: object) {
    return request('GET', '/adPositionApi/update.do', params)
  },
  // 更改投放位状态
  updateAdPositionStatus(params?: object) {
    return request('GET', '/adPositionApi/updateStatus.do', params)
  },
  /*--------------------------- 系统日志 -----------------------------*/
  // 获取系统日志
  getSystemLog(params?: object) {
    return request('GET', '/systemLogApi/page.json', params)
  },
  // 获取操作类型
  getOperationType(params?: object) {
    return request('GET', '/systemLogApi/operationType.json', params)
  },
}

export default api