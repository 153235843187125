import api from 'api/api'
import { makeAutoObservable } from 'mobx'

interface IUser {
  account: any
  role: any
}

class User {
  constructor() {
    makeAutoObservable(this)
    this.getUserInfo()
    this.getUnionCorp()
  }
  user = {
    account: {},
    role: {}
  } as IUser            // 用户信息 
  currentOrg: any = {}  // 当前机构 

  getUserInfo() {
    api.getUserInfo()
      .then((res) => {
        this.user = res.data.data
      })
  }
  
  getUnionCorp() {
    api.getUnionCorp()
      .then((res) => {
        this.currentOrg = res.data.data
      })
  }
}

export default new User()