import api from 'api/api'
import { makeAutoObservable } from 'mobx'

class Resource {
  constructor() {
    makeAutoObservable(this)
    this.getResourceColumn()
  }
  resourceColumn: any = []   // 所有素材分组

  getResourceColumn() {
    api.getResourceColumn()
      .then((res) => {
        this.resourceColumn = res.data.data
      })
  }
}

export default new Resource()