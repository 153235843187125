import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Login from '../Login'
import Main from '../Main'

const Index = () => {

  return (
    <Router>
      <Switch>       
          <Route path="/login" component={Login} /> 
          <Route path="/" component={Main} />
      </Switch>
    </Router>
  )
}

export default Index